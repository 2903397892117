  //  const baseURL = 'http://192.168.2.3:9091/';
   
// 测试
 const baseURL = 'https://www.docter.com.cn/apidoctor/';
//  const dominUrlvform = 'https://www.docter.com.cn/devform';
//  const dominPatient = 'https://www.docter.com.cn/devpatient/';
//  const dominDoctor =  'https://www.docter.com.cn/devdoctor/';
//正式
// const baseURL = 'https://www.docter.com.cn/apidoctor';

 const dominPatient = 'https://www.docter.com.cn/patient/';
 const dominDoctor =  'https://www.docter.com.cn/doctor/';
 const dominUrlvform = 'https://www.docter.com.cn/vform';
// 测试跳转表单配置

// 正式跳转表单配置
 const imgUrl = 'https://aibiboat.oss-cn-shanghai.aliyuncs.com/'; //鉴权图片预览地址
const dominUrl = "https://www.docter.com.cn";

const dominUrl2 = 'https://www.docter.com.cn/doctor/';
const downloadUrl = "https://aibiboat.oss-cn-shanghai.aliyuncs.com/";
export default {
  baseURL,
  imgUrl,
  dominPatient,
  dominDoctor,
  dominUrlvform,
  dominUrl,

  dominUrl2,
  downloadUrl
}