<template>
  <div class="app-fly-map">
    <div class="province-map" id="anhui-map"></div>
  </div>
</template>

<script>
import anhuiGeoJson from "../../assets/anhui";
export default {
  name: "flyMap",
  data() {
    return {
      options: {
        tooltip: {
          trigger: "item",
          // 自定义格式化tooltip的内容
          formatter: function (params) {
            console.log(params);
            return params.name + ": " + params.value[2];
          },
        },
        geo: {
          map: "city",
          scaleLimit: {
            min: 1,
            max: 2,
          },
          zoom: 1,
          top: 20,
          label: {
            normal: {
              show: true,
              fontSize: "14",
              color: "rgba(255, 255, 255, 0.7)",
            },
            emphasis: {
              color: "#fff",
            },
          },
          itemStyle: {
            normal: {
              borderColor: "rgba(0, 0, 0, 0.2)",
              areaColor: "#3b9cff", // 设置地图区域背景色
            },
            emphasis: {
              areaColor: "#ceaf06",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
            },
          },
        },
        series: [
          {
            // 散点图（气泡图）
            type: "effectScatter",
            // 使用地理坐标系
            coordinateSystem: "geo",
            // 配置何时显示特效(绘制完成后显示特效)
            showEffectOn: "render",
            // 标记的大小(默认为10)
            symbolSize: 10,
            // 图层
            zlevel: 1,
            // 数据
            data: [
              { name: "黄山市", value: [118.317325, 29.709239] },
              { name: "太和县", value: [115.621145, 33.188927] },
              { name: "亳州市", value: [115.782939, 33.869338] },
              { name: "铜陵市", value: [117.816576, 30.929935] },
              { name: "宿州市", value: [116.984084, 33.633891] },
              { name: "合肥市", value: [117.283042, 31.86119] },
            ],
            itemStyle: {
              color: "#ddb926",
            },
            // 涟漪特效相关配置
            rippleEffect: {
              // 动画周期
              period: 5,
              // 缩放比例
              scale: 4,
              // 波纹的绘制
              brushType: "fill",
            },
          },
          {
            // 线性图
            type: "lines",
            // 使用坐标系
            coordinateSystem: "geo",
            // 图层
            zlevel: 2,
            // 线特效的配置
            effect: {
              // 是否显示特效
              show: true,
              // 特效动画的时间
              period: 2,
              // 特效的大小
              symbolSize: 10,
              // 拖尾的效果，取值范围 0-1，值越大效果越明显
              trailLength: 0.1,
            },
            lineStyle: {
              normal: {
                color: "#ffffff",
                width: 1,
                opacity: 0,
                curveness: 0.7,
              },
            },
            data: [
              {
                coords: [
                  [115.621145, 33.188927],
                  [118.317325, 29.709239],
                ],
                lineStyle: {
                  normal: {
                    curveness: -0.3,
                  },
                },
              },
              {
                coords: [
                  [115.782939, 33.869338],
                  [118.317325, 29.709239],
                ],
                lineStyle: {
                  normal: {
                    curveness: -0.1,
                  },
                },
              },
              {
                coords: [
                  [117.816576, 30.929935],
                  [118.317325, 29.709239],
                ],
                lineStyle: {
                  normal: {
                    curveness: 0,
                  },
                },
              },
              {
                coords: [
                  [116.984084, 33.633891],
                  [118.317325, 29.709239],
                ],
                lineStyle: {
                  normal: {
                    curveness: 0.32,
                  },
                },
              },
              {
                coords: [
                  [117.283042, 31.86119],
                  [118.317325, 29.709239],
                ],
                lineStyle: {
                  normal: {
                    curveness: 0.25,
                  },
                },
              },
            ],
          },
        ],
      },
      cityList: [
        { name: "宿州市", value: [116.984084, 33.633891] },
        { name: "淮北市", value: [116.794664, 33.971707] },
        { name: "亳州市", value: [115.782939, 33.869338] },
        { name: "蚌埠市", value: [117.363228, 32.939667] },
        { name: "阜阳市", value: [115.819729, 32.896969] },
        { name: "淮南市", value: [117.018329, 32.647574] },
        { name: "滁州市", value: [118.316264, 32.303627] },
        { name: "六安市", value: [116.507676, 31.752889] },
        { name: "合肥市", value: [117.283042, 31.86119] },
        { name: "马鞍山市", value: [118.507906, 31.689362] },
        { name: "芜湖市", value: [118.376451, 31.326319] },
        { name: "铜陵市", value: [117.816576, 30.929935] },
        { name: "宣城市", value: [118.757995, 30.945667] },
        { name: "安庆市", value: [117.043551, 30.50883] },
        { name: "池州市", value: [117.489157, 30.656037] },
        { name: "黄山市", value: [118.317325, 29.709239] },
      ],
      cityJson: {
        features: [],
      },
    };
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.initEchartMap()
    // })
  },
  methods: {
    initEchartMap(data) {
      let mapDiv = document.getElementById("anhui-map");
      let myChart = this.$echarts.init(mapDiv);
      //动态引入省份json文件
      this.cityJson = anhuiGeoJson;
      this.$echarts.registerMap("city", this.cityJson, {});
      let dataList = [];
      if (data.countPatienceByCity && data.countPatienceByCity.length) {
        data.countPatienceByCity.forEach((ele) => {
          let obj = {};
          // let obj = {
          //   name: ele.city_name,
          //   value: ele.patient_city,
          // };
          // dataList.push(obj);
          this.cityList.forEach((chr) => {
            if (ele.city_name == chr.name) {
              obj = {
                name: ele.city_name,
                value: [...chr.value,ele.patientCount],
              };
              // obj.value[2] = ele.patientCount;
            }
          });
          dataList.push(obj);
        });
        this.options.series[0].data = dataList;
      }
      // this.setEchartOption()
      myChart.setOption(this.options);
    },
    setEchartOption() {
      //红色标注点的坐标
      let markList = [{ name: "黄山市", value: [118.317325, 29.709239] }];
      this.options.series[1].data = markList;
      if (markList.length > 0) {
        this.options.series[1].renderItem = function (params, api) {
          return {
            type: "image",
            name: markList[params.dataIndex].name,
            style: {
              image: require("@/assets/icon_mark.png"), //标注点图标
              width: 14,
              height: 14,
              x: api.coord([
                markList[params.dataIndex].value[0],
                markList[params.dataIndex].value[1],
              ])[0],
              y: api.coord([
                markList[params.dataIndex].value[0],
                markList[params.dataIndex].value[1],
              ])[1],
            },
          };
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.app-fly-map {
  position: relative;
  height: 100%;

  .province-map {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
</style>
