<template>
  <div class="app-age-pie">
    <div class="age-chart" id="age-line"></div>
  </div>
</template>

<script>
export default {
  name: "agePie",
  data() {
    return {
      chartEle: null,
      chartData: ["155", "400", "900", "800", "300"],
    };
  },
  mounted() {
    // this.$nextTick(function () {
    //   this.drawLine()
    // })
  },
  methods: {
    drawLine(data) {
      const the = this;
      let myData = data.patientCount[0];
      console.log(myData);
      the.chartEle = the.$echarts.init(document.getElementById("age-line"));
      the.chartEle.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}个",
        },
        title: {
          left: "center", // left的值为'left', 'center', 'right'
          top: "10%", // top middle bottom
          itemGap: 20, // 主副标题之间的间距，默认为10
          text: "患者年龄分布", // 标题文本
          // 标题文本样式
          textStyle: {
            color: "#ffffff", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 18, //字体大小
          },
        },
        grid: {
          width: "100%", // 设置网格宽度为80%
          left: "center", // 将网格居中
        },
        xAxis: {
          type: "category",
          data: ["30岁以下", "30-40岁", "40-50岁", "50-60岁", "60岁以上"],
          axisLabel: {
            textStyle: {
              //文字样式
              color: "#ffffff",
              fontSize: "12",
            },
          },
        },
        yAxis: {
          type: "value",
          show: false, // 设置Y轴不显示
        },
        series: [
          {
            data: [
              { value: myData.thirty, itemStyle: { color: "#FFDB5C" } },
              { value: myData.forty, itemStyle: { color: "#37A2DA" } },
              { value: myData.fifty, itemStyle: { color: "#9FE6B8" } },
              { value: myData.sixty, itemStyle: { color: "#67E0E3" } },
              { value: myData.other, itemStyle: { color: "#32C5E9" } },
            ],
            type: "bar",
            label: {
              show: true,
              position: "inside",
              formatter: "{c}",
              color: "#ffffff",
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
.app-age-pie {
  position: relative;
  height: 100%;

  .age-chart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -10%;
  }
}
</style>
