<template>
  <div class="app-hospital gb-box">
    <div class="box">
      <div class="title">机构概况</div>
      <div class="bd">
        <dv-scroll-board :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "hospital",
    data() {
      return {
        config: {
          header: ['时间', '医院', '医生'],
          data: [
            ['2019-07-01', '太和县中医院', '马峰'],
            ['2019-07-02', '铜陵市中医院', '刘红霞'],
            ['2019-07-03', '黄山市中医院', '吴朝霞'],
            ['2019-07-04', '亳州市中医院', '刘玉峰'],
            ['2019-07-01', '太和县中医院', '马峰'],
            ['2019-07-02', '铜陵市中医院', '刘红霞'],
            ['2019-07-03', '黄山市中医院', '吴朝霞'],
            ['2019-07-04', '亳州市中医院', '刘玉峰']
          ],
          index: true,
          columnWidth: [50, 120, 200],
          align: ['center'],
          rowNum: 7,
          headerBGC: '#1981f6',
          headerHeight: 45,
          oddRowBGC: 'rgba(0, 44, 81, 0.8)',
          evenRowBGC: 'rgba(10, 29, 50, 0.8)'
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.app-hospital {

}
</style>
