import { render, staticRenderFns } from "./agePie.vue?vue&type=template&id=58e5ddaa&scoped=true"
import script from "./agePie.vue?vue&type=script&lang=js"
export * from "./agePie.vue?vue&type=script&lang=js"
import style0 from "./agePie.vue?vue&type=style&index=0&id=58e5ddaa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58e5ddaa",
  null
  
)

export default component.exports