<template>
  <div class="app-center-case">
    <div class="case-line-chart" id="case-line"></div>
  </div>
</template>

<script>
export default {
  name: "centerCase",
  data() {
    return {
      chartEle: null,
      chartData: ["155", "400", "900", "800", "300", "900", "270"],
      index: 0,
      series: [
        {
          series: [
            {
              data: [
                100, 110, 115, 120, 140, 120, 105, 110, 105, 125, 130, 117,
              ],
            },
            {
              data: [50, 75, 88, 90, 105, 100, 120, 115, 107, 142, 115, 110],
            },
          ],
        },
        {
          series: [
            {
              data: [110, 100, 125, 93, 112, 150, 95, 120, 115, 105, 113, 107],
            },
            {
              data: [90, 75, 102, 80, 95, 73, 100, 105, 117, 122, 105, 100],
            },
          ],
        },
      ],
      lasestYear: [],
    };
  },
  mounted() {
    // this.$nextTick(function () {
    //   this.drawLine()
    // })
    this.requestMonth();
  },
  methods: {
    //  获取申请月份list
    requestMonth() {
      let date = new Date();
      let newArrTimeYear = date.getFullYear(); //获取完整的年份(4位)
      let arrTimeMonth = date.getMonth() + 2; //获取当前月份(0-11,0代表1月)
      let arr = [];
      for (let i = arrTimeMonth - 12; i < arrTimeMonth; i++) {
        let obj = { m: 0, y: 0, data: "", title: "" };
        if (i < 1) {
          obj.m = i + 12;
          obj.y = newArrTimeYear - 1;
        } else {
          obj.m = i;
          obj.y = newArrTimeYear;
        }
        if (obj.m < 10) {
          obj.data = obj.y + "-0" + obj.m;
          obj.title = obj.y + "年0" + obj.m + "月";
        } else {
          obj.data = obj.y + "-" + obj.m;
          obj.title = obj.y + "年" + obj.m + "月";
        }
        arr.push(obj);
      }
      this.lasestYear = arr;
      console.log(arr);
    },
    drawLine(data) {
      const the = this;
      the.chartEle = the.$echarts.init(document.getElementById("case-line"));
      let arr = [];
      let xAxisList = [];
      let date = new Date();
      let newArrTimeYear = date.getFullYear(); //获取完整的年份(4位)
      data.patiencesByYear.forEach((ele) => {
        this.lasestYear.forEach((chr) => {
          if (chr.data.indexOf(ele.time) > -1) {
            ele.month = newArrTimeYear + "-" + ele.time;
          }
        });
      });

      data.groupVisitInfoByYear.forEach((ele) => {
        this.lasestYear.forEach((chr) => {
          if (chr.data.indexOf(ele.time) > -1) {
            ele.month = newArrTimeYear + "-" + ele.time;
          }
        });
      });
      arr =
        data.patiencesByYear.length > data.groupVisitInfoByYear.length
          ? data.patiencesByYear
          : data.groupVisitInfoByYear;
      xAxisList = arr.map((chr) => {
        return chr.month;
      });
      xAxisList = [...xAxisList].sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      );
      let series = data.patiencesByYear.map((chr) => {
        return chr.number;
      });
      let series2 = data.groupVisitInfoByYear.map((chr) => {
        return chr.number;
      });
      the.chartEle.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}",
        },
        title: {
          left: "center", // left的值为'left', 'center', 'right'
          top: "0", // top middle bottom
          itemGap: 20, // 主副标题之间的间距，默认为10
          text: "近一年病例数/患者数", // 标题文本
          // 标题文本样式
          textStyle: {
            color: "#ffffff", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 18, //字体大小
          },
        },
        grid: {
          width: "100%", // 设置网格宽度为80%
          left: "center", // 将网格居中
        },
        legend: {
          top: "12%",
          textStyle: {
            color: "#ffffff", // 设置图例文字的颜色为红色
          },
          data: ["患者数", "病例数"],
        },
        xAxis: {
          type: "category",
          data: xAxisList,
          axisLabel: {
            textStyle: {
              //文字样式
              color: "#ffffff",
              fontSize: "12",
            },
            //X轴刻度配置
            interval: 0, // 0：表示全部显示不间隔；auto:表示自动根据刻度个数和宽度自动设置间隔个数
            rotate: -40, // 倾斜度可以根据需求设置
          },
        },
        yAxis: {
          type: "value",
          show: false, // 设置Y轴不显示
        },
        series: [
          {
            name: "患者数",
            data: series,
            type: "line",
            itemStyle: {
              normal: {
                // 设置线条颜色
                color: "#FFDB5C",
                // 设置区域填充样式为渐变
                areaStyle: {
                  color: {
                    type: "linear",
                    x: 0, // 起始位置横坐标，0 为最左侧，0.5 为中间，1 为最右侧
                    y: 0, // 起始位置纵坐标，0 为最顶部，0.5 为中间，1 为最底部
                    x2: 0, // 结束位置横坐标
                    y2: 1, // 结束位置纵坐标，1 为最底部
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(255, 219, 92, 1)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(255, 219, 92, 0)", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              },
            },
          },
          {
            name: "病例数",
            data: series2,
            type: "line",
            itemStyle: {
              normal: {
                // 设置线条颜色
                color: "#32C5E9",
                // 设置区域填充样式为渐变
                areaStyle: {
                  color: {
                    type: "linear",
                    x: 0, // 起始位置横坐标，0 为最左侧，0.5 为中间，1 为最右侧
                    y: 0, // 起始位置纵坐标，0 为最顶部，0.5 为中间，1 为最底部
                    x2: 0, // 结束位置横坐标
                    y2: 1, // 结束位置纵坐标，1 为最底部
                    colorStops: [
                      {
                        offset: 0,
                        color: "rgba(50, 197, 233, 1)", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "rgba(50, 197, 233, 0)", // 100% 处的颜色
                      },
                    ],
                    global: false, // 缺省为 false
                  },
                },
              },
            },
          },
        ],
      });
      // setInterval(the.updateChart, 5000);
    },
    updateChart() {
      this.index = this.index ? 0 : 1;
      const newData = this.series[this.index];
      this.chartEle.setOption(newData);
    },
  },
};
</script>

<style lang="less" scoped>
.app-center-case {
  position: relative;
  height: 100%;

  .case-line-chart {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -15%;
  }
}
</style>
