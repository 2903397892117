<template>
  <div class="digital-flop" v-if="digitalFlopData.length">
    <div class="box">
      <div class="item" v-for="(item, index) in digitalFlopData" :key="index">
        <div class="label">{{ item.title }}</div>
        <div class="value">
          <div class="numbers">
            <dv-digital-flop :config="item.number" style="height: 100%" />
          </div>
          <div class="unit">{{ item.unit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "digitalFlop",
  data() {
    return {
      digitalFlopData: [],
      data: "",
    };
  },
  methods: {
    async createData(obj) {
      const { randomExtend } = this;
      this.data = JSON.parse(JSON.stringify(obj));
      let patientNum = 0;
      let visitNum = 0;
      if (this.data.patientCount && this.data.patientCount.length) {
        let patientCount = this.data.patientCount[0];
        patientNum =
          patientCount.thirty +
          patientCount.forty +
          patientCount.fifty +
          patientCount.sixty +
          patientCount.other;
      }
      if (this.data.visitCount && this.data.visitCount.length) {
        this.data.visitCount.forEach(ele=>{
          visitNum = visitNum + ele.number;
        })
      }
      this.digitalFlopData = [
        {
          title: "中心构数",
          number: {
            number: [parseInt(this.data.teamsCount)],
            content: "{nt}",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "家",
        },
        {
          title: "中心医生",
          number: {
            number: [parseInt(this.data.teamsMembers)],
            // number: [randomExtend(200, 500)],
            content: "{nt}",
            style: {
              fill: "#f46827",
              fontWeight: "bold",
            },
          },
          unit: "名",
        },
        {
          title: "患者",
          number: {
            number: [patientNum],
            content: "{nt}",
            style: {
              fill: "#40faee",
              fontWeight: "bold",
            },
          },
          unit: "名",
        },
        {
          title: "病例",
          number: {
            number: [visitNum],
            content: "{nt}",
            style: {
              fill: "#4d99fc",
              fontWeight: "bold",
            },
          },
          unit: "个",
        },
      ];
    },
    randomExtend(minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10);
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.digital-flop {
  position: relative;
  height: 100%;
  padding-top: 5%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 3%;

  .box {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: row wrap;

    &:before {
      content: "";
      position: absolute;
      height: 2px;
      left: 5%;
      right: 55%;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgb(6, 30, 93);
    }

    &:after {
      content: "";
      position: absolute;
      height: 2px;
      left: 55%;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgb(6, 30, 93);
    }

    .item {
      flex-basis: 50%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      &:nth-child(even) {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 4px;
          top: 5%;
          bottom: 5%;
          left: -2px;
          background-color: rgb(6, 30, 93);
        }
      }

      .label {
        flex-basis: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        font-size: 0.09rem;
        color: #ffffff;
        font-weight: 600;
      }

      .value {
        flex: 1;
        flex-shrink: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

        .numbers {
          flex: 1;
          width: 100%;
          overflow: hidden;
        }

        .unit {
          flex-basis: 40%;
          width: 100%;
          flex-shrink: 0;
          text-align: center;
          color: rgba(255, 255, 255, 0.5);
          font-size: 0.08rem;
        }
      }
    }
  }
}
</style>
